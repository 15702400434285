import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import * as XLSX from 'xlsx';
import {
  Row, Col, Card, Table, Button, Input, AutoComplete,
  notification, Modal, Select, Avatar, Typography, TableColumnsType,
  Checkbox, DatePicker, Drawer, Form, InputNumber, Space, Tooltip, Upload
} from "antd";


import axios from "axios";
import { baseUrl } from "../../config";
import {
  DeleteOutlined, FileExcelOutlined, CalendarOutlined, SendOutlined,
  FilterOutlined, EditOutlined, ExclamationCircleOutlined, SearchOutlined,
  EyeOutlined, UserOutlined, PlusOutlined, UploadOutlined
} from "@ant-design/icons";
import userDummy from "../../assets/images/dummy-user.png";
import { getPermissions, hasPermission } from '../../components/permissions';
import '../../assets/styles/custom_style.css';
import moment from 'moment';

import AddUserDetailsModel from './Form';
import AddMessage from "./components/purnimaForm";
import AddImageForm from "./components/purnimaFormWithImg";
import ImportListModal from './components/importListModal';
import InquiryForm from './Form';

const { confirm } = Modal;
const { Search } = Input;
const { Option } = Select;
const { Title } = Typography;
const { Item } = Form;
const { TextArea } = Input;


// Table columns definition
const columns = [

  {
    title: "#",
    dataIndex: "key",
    key: "key",
    fixed: 'left',
    width: 50,
  },
  {
    title: "Phone no",
    dataIndex: "phone_no",
    key: "phone_no",
    fixed: 'left',
    width: 150,
  },

  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Received Call",
    dataIndex: "received_call",
    key: "received_call",
  },
  {
    title: "Inquiry Regards",
    dataIndex: "inquiry_regards",
    key: "inquiry_regards",
  },
  {
    title: 'Inquiry Date',
    dataIndex: 'inquiry_date',
    key: 'inquiry_date',
  },

  {
    title: "Attend By",
    dataIndex: "attend_by_name",
    key: "attend_by_name"
  },
  {
    title: "Assigned To",
    dataIndex: "attend_by_name",
    key: "attend_by_name",
  },
  {
    title: "Resolved Remark",
    dataIndex: "resolved_remark",
    key: "resolved_remark",
  },

  {
    title: "Resolved Date",
    dataIndex: "resolved_date",
    key: "resolved_date",
  },

  {
    title: "Start Date",
    dataIndex: "start_date",
    key: "start_date",
  },


  {
    title: "CreatedAt",
    dataIndex: "createdAt",
    key: "createdAt",
  },

  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    fixed: 'right',
  },
  {
    title: "Action",
    key: "action",
    dataIndex: "action",
    fixed: 'right',
  }
];

function Inquiry() {

  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const currentPageQuery = parseInt(queryParams.get('page')) || 1;
  const limitQuery = parseInt(queryParams.get('limit')) || 20;

  const [userList, setUserList] = useState([]);
  const [pagination, setPagination] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(limitQuery);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [search, setSearch] = useState('');
  const [staffList, setStaffList] = useState([]);
  const [purnimaSettingRecord, setPurnimaSettingRecord] = useState();
  const [filters, setFilters] = useState({
    name: queryParams.get('name') || '',
    email: queryParams.get('email') || '',
    phone: queryParams.get('phone') || '',
    inquiry_regards: queryParams.get('inquiry_regards') || '',
    inquiry_date: queryParams.get('inquiry_date') || '',
    attend_by: queryParams.get('attend_by') || '',
    assigned_to: queryParams.get('assigned_to') || '',
    resolved_remark: queryParams.get('resolved_remark') || '',
    resolved_date: queryParams.get('resolved_date') || '',
    status: queryParams.get('status') || '',
    createdAt: queryParams.get('createdAt') || '',
  });

  const [showUserFilter, setShowUserFilter] = useState(true);
  const [showPurnimaMessageForm, setShowPurnimaMessageForm] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleImportList, setIsModalVisibleImportList] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const { id } = useParams(); // Extract id from URL 
  const [form] = Form.useForm();
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [txnDate, setTxnDate] = useState();
  const [meetingDate, setMeetingDate] = useState();
  const [addForm, setAddForm] = useState(false);
  const [sendMessage, setSendMessage] = useState(false);
  const [AddUserDetailsFormModel, setAddUserDetailsModel] = useState(false);
  const [userId, setuserId] = useState();


  useEffect(() => {
    getStaffList();
    const { state } = history.location; // Get the state from history
    const restoredPage = state?.page || currentPage; // Restore page from state or use current page
    const restoredLimit = state?.limit || limit; // Restore limit from state or use current limit

    // Call getUserList with restored page and limit
    getUserList(restoredPage, restoredLimit, sortField, sortOrder, search, filters);
  }, [history.location]);


  const AddUserDetailsClose = () => {
    setAddUserDetailsModel(false)
    const { state } = history.location; // Get the state from history
    const restoredPage = state?.page || currentPage; // Restore page from state or use current page
    const restoredLimit = state?.limit || limit; // Restore limit from state or use current limit

    // Call getUserList with restored page and limit
    getUserList(restoredPage, restoredLimit, sortField, sortOrder, search, filters);
  }



  async function getUserList(page = 1, limit = 20, sortField = null, sortOrder = null, search = '', filters = {}) {
    try {
      setCurrentPage(page);
      setLimit(limit);
      const sortParam = sortField && sortOrder ? `&sortField=${sortField}&sortOrder=${sortOrder}` : '';
      const searchParam = search ? `&search=${search}` : '';
      const filterParams = Object.keys(filters)
        .map(key => `${key}=${filters[key]}`)
        .join('&');
      const response = await axios.get(`${baseUrl}/inquiry/list?page=${page}&limit=${limit}${sortParam}${searchParam}&${filterParams}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        }
      });
      if (response.data.status) {
        setUserList(response.data.result);
        // setUserList([])
        setPagination({
          current: page,
          pageSize: limit,
          total: response.data.totalCount
        });
      } else {
        notification.info({
          message: 'Info',
          description: response.data.message,
          placement: 'topRight'
        });
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }
  };

  async function handleDelete(id) {
    try {
      const response = await axios.delete(`${baseUrl}/inquiry/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      if (response.status === 200) {
        getUserList(pagination.current, pagination.pageSize, sortField, sortOrder, search, filters);
        notification.success({
          message: 'Success',
          description: 'Record deleted successfully!',
          placement: 'topRight'
        });
      } else {
        notification.info({
          message: 'Info',
          description: response.data.message,
          placement: 'topRight'
        });
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: 'Are you sure you want to delete this item?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDelete(id);
      },
    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const { field, order } = sorter;
    const formattedOrder = order === 'ascend' ? 'asc' : 'desc';
    setSortField(field);
    setSortOrder(formattedOrder);
    setFilters(filters);

    // Update both current page and limit in the history
    history.push(`?page=${current}&limit=${pageSize}`);

    // Fetch the updated payment list
    getUserList(current, pageSize, field, formattedOrder, search, filters);
  };


  const handleLimitChange = value => {
    setLimit(value);

    // Update URL with the new limit and reset page to 1
    const updatedQueryParams = new URLSearchParams(location.search);
    updatedQueryParams.set('limit', value);
    updatedQueryParams.set('page', 1); // Reset to first page whenever limit changes

    // Push new URL with updated query params
    history.push(`?${updatedQueryParams.toString()}`);

    // Call the API to fetch data with updated limit
    getUserList(1, value);  // Assuming your API call accepts page and limit
  };


  const handleFilterChange = (field, value) => {
    const newFilters = {
      ...filters,
      [field]: value
    };
    setFilters(newFilters);

    // Optionally, update the URL without triggering a search immediately
    const updatedQueryParams = new URLSearchParams(location.search);
    Object.keys(newFilters).forEach(key => {
      if (newFilters[key]) {
        updatedQueryParams.set(key, newFilters[key]);
      } else {
        updatedQueryParams.delete(key); // Remove empty filters from the URL
      }
    });
    history.push(`?${updatedQueryParams.toString()}`);
  };


  const handleUserFilterCheckboxChange = (e) => {
    setShowUserFilter(e.target.checked);

  };


  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCancelImportList = () => {
    setIsModalVisibleImportList(false);
  };

  const handleOnSuccess = () => {
    setIsModalVisibleImportList(false);
    getUserList();
  };



  const toggleFormVisibility = () => {
    setAddForm(prevState => !prevState); // Toggle between true and false
  };


  const AddUserDetails = async (userId) => {
    console.log('uid', userId)
    setuserId(userId);
    setAddUserDetailsModel(true)

  }

  async function getStaffList() {
    try {
      const response = await axios.get(`${baseUrl}/staff/list`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        }
      });
      if (response.data.status) {
        setStaffList(response.data.result);
      } else {
        notification.info({
          message: 'Info',
          description: response.data.message,
          placement: 'topRighft'
        });
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }
  };

  const onRefresh = async () => {
    setAddForm(false);
    getUserList();
  }

  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>

        {addForm && (
          <Col xs="24" xl={24}>
            <InquiryForm userId={null} onRefreshData={onRefresh} />
          </Col>

        )}
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Call/Message Inquiry  List"
            extra={
              <div style={{ display: 'flex', gap: '10px' }}>
                {hasPermission('createPermission', 'Create User') && (
                  <button onClick={toggleFormVisibility} className="custom-btn">Add Inquiry Form</button>
                )}
              </div>
            }

          >
            <div className="table-responsive">
              <Table
                columns={columns}
                pagination={false}
                scroll={{ x: 'max-content' }}
                bordered
                title={() => (
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Row gutter={[16, 16]}>
                      <Col xs={24}>
                        <FilterOutlined /> <Checkbox onChange={handleUserFilterCheckboxChange}><Title style={{ fontSize: 13 }} level={5}>Form Filter</Title></Checkbox>
                      </Col>
                      {showUserFilter && (
                        <>
                          <Col span={4}> <Input placeholder="Phone no" value={filters.phone_no} onChange={e => handleFilterChange('phone_no', e.target.value)} /></Col>

                          <Col span={4}><Input placeholder="Name" value={filters.name} onChange={e => handleFilterChange('name', e.target.value)} /></Col>
                          <Col span={4}> <Input placeholder="Inquiry Regards" value={filters.inquiry_regards} onChange={e => handleFilterChange('inquiry_regards', e.target.value)} /></Col>
                          <Col span={4}>
                            <DatePicker className="filter_dob CustomInputSet"
                              format="DD-MM-YYYY" value={filters.inquiry_date ? moment(filters.inquiry_date, 'DD-MM-YYYY') : null}
                              onChange={(date, dateString) => handleFilterChange('inquiry_date', dateString)} placeholder="Inquiry Date"
                            />
                          </Col>

                          <Col span={4}>

                            <Select style={{ width: '100%' }} placeholder="Attend By" value={filters.attend_by} onChange={value => handleFilterChange('attend_by', value)}>

                              <Option value=""> Attend By</Option>
                              {staffList && staffList.map((item, i) => (
                                <Option value={item.id}>{item.firstName} {item.lastName}</Option>
                              ))}
                            </Select>
                          </Col>

                          <Col span={4}>

                            <Select style={{ width: '100%' }} placeholder="Received Call No." value={filters.received_call} onChange={value => handleFilterChange('received_call', value)}>

                              <Option value=""> Received Call No.</Option>
                             
                                <Option value="9001000071">9001000071</Option>
                                <Option value="7023131008">7023131008</Option>
                                <Option value="9030301008">9030301008</Option>
                                <Option value="9030191008">9030191008</Option>
                                <Option value="9030131008">9030131008</Option>
                                <Option value="9492861008">9492861008</Option>
                                <Option value="9492871008">9492871008</Option>
                                <Option value="9492881008">9492881008</Option>
                                <Option value="9951513144">9951513144</Option>
                                <Option value="9951516145">9951516145</Option>
                                <Option value="9951516155">9951516155</Option>
                             
                            </Select>
                          </Col>


                          <Col span={4}>

                            <Select style={{ width: '100%' }} placeholder="Assigned To" value={filters.assigned_to} onChange={value => handleFilterChange('assigned_to', value)}>

                              <Option value="">Assigned To</Option>
                              {staffList && staffList.map((item, i) => (
                                <Option value={item.id}>{item.firstName} {item.lastName}</Option>
                              ))}
                            </Select>
                          </Col>
                          <Col span={4}>
                            <Input placeholder="Resolved Remark" value={filters.resolved_remark} onChange={e => handleFilterChange('resolved_remark', e.target.value)}
                            />
                          </Col>

                          <Col span={4}>
                            <DatePicker className="filter_dob CustomInputSet"
                              format="DD-MM-YYYY" value={filters.resolved_date ? moment(filters.resolved_date, 'DD-MM-YYYY') : null}
                              onChange={(date, dateString) => handleFilterChange('resolved_date', dateString)} placeholder="Resolved Date"
                            />
                          </Col>


                          <Col span={4}>

                            <Select style={{ width: '100%' }} placeholder="Status" value={filters.status} onChange={value => handleFilterChange('status', value)}>
                              <Option value="">Select Status</Option>
                              <Option value="Pending">Pending</Option>
                              <Option value="InProcess">InProcess</Option>
                              <Option value="Completed">Completed</Option>

                            </Select>


                          </Col>

                          <Col span={4}>
                            <DatePicker className="filter_dob CustomInputSet"
                              format="DD-MM-YYYY" value={filters.createdAt ? moment(filters.createdAt, 'DD-MM-YYYY') : null}
                              onChange={(date, dateString) => handleFilterChange('createdAt', dateString)} placeholder="Created Date"
                            />
                          </Col>


                        </>
                      )}

                      <Col xs={24}>
                        <span style={{ marginRight: 10 }}>Records per page:</span>
                        <Select defaultValue={20} onChange={handleLimitChange}>
                          <Option value={20}>20</Option>
                          <Option value={40}>40</Option>
                          <Option value={60}>60</Option>
                          <Option value={80}>80</Option>
                          <Option value={100}>100</Option>
                        </Select>
                      </Col>
                    </Row>
                  </div>
                )}
                dataSource={userList.map((user, index) => {
                  const isMatch = user.phone_no === user.user_phone_no;
                  return {
                    key: (limit * (currentPage - 1)) + index + 1,
                    phone_no: (
                      <div className="author-info">

                        <Title
                          level={5}
                          style={{
                            textAlign: 'center',
                            color: isMatch ? 'white' : 'inherit',
                            backgroundColor: isMatch ? 'green' : 'inherit',
                            padding: isMatch ? '5px' : 'inherit',
                            borderRadius: isMatch ? '5px' : 'inherit'
                          }}
                        >
                          {user.phone_no}
                        </Title>

                      </div>
                    ),
                    name: (
                      <>
                        <div className="author-info">
                          <Title level={5}> {user.name}</Title>
                        </div>
                      </>
                    ),
                    received_call: (
                      <>
                        <div className="author-info">
                          <Title level={5}>{user.received_call}  </Title>
                        </div>
                      </>
                    ),
                    inquiry_regards: (
                      <>
                        <div className="author-info">
                          <Title level={5}> {user.inquiry_regards}</Title>
                        </div>
                      </>
                    ),
                    inquiry_date: (
                      <>
                        <div className="author-info">
                          <Title level={5}>{user.inquiry_date && user.inquiry_date != "0000-00-00" ? moment(user.inquiry_date).format('DD MMMM YYYY') : '-'}</Title>
                        </div>
                      </>
                    ),
                    attend_by_name: (
                      <>
                        <div className="author-info">
                          <Title level={5}>{user.attend_by_name}  </Title>
                        </div>
                      </>
                    ),
                    assigned_to_name: (
                      <>
                        <div className="author-info">
                          <Title level={5}>{user.assigned_to_name}  </Title>
                        </div>
                      </>
                    ),


                    resolved_remark: (
                      <>
                        <div className="author-info">
                          <Title level={5}>{user.resolved_remark}  </Title>
                        </div>
                      </>
                    ),
                    resolved_date: (
                      <>
                        <div className="author-info">
                          <Title level={5}>{user.resolved_date && user.resolved_date != "0000-00-00" ? moment(user.resolved_date).format('DD MMMM YYYY') : '-'}</Title>

                        </div>
                      </>
                    ),
                    status: (
                      <>
                        <div className="author-info">
                          <Title level={5}>{user.status}  </Title>
                        </div>
                      </>
                    ),

                    start_date: (
                      <>
                        <div className="author-info">
                          <Title level={5}>{user.start_date && user.start_date != "0000-00-00" ? moment(user.start_date).format('DD MMMM YYYY') : '-'}</Title>

                        </div>
                      </>
                    ),


                    createdAt: (
                      <>
                        <div className="author-info">
                          <Title level={5}>{user.createdAt && user.createdAt != "0000-00-00" ? moment(user.createdAt).format('DD MMMM YYYY') : '-'}</Title>

                        </div>
                      </>
                    ),
                    action: (
                      <div className="button-container" style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>

                        <Button onClick={() => AddUserDetails(user.id)} className="update-btn">
                          <EditOutlined />
                        </Button>

                        <Button onClick={() => showDeleteConfirm(user.id)} className="delete-btn">
                          <DeleteOutlined />
                        </Button>
                      </div>
                    )
                  };
                })}
                pagination={{
                  ...pagination,
                  showTotal: total => `Total ${total} records`,
                  style: { marginRight: 20 }
                }}
                onChange={handleTableChange}
                className="ant-border-space"
              />
            </div>
          </Card>
        </Col>
      </Row>

      <Modal
        open={isModalVisible}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          src={currentImage}
          alt="Profile"
          style={{ width: '100%' }}
        />
      </Modal>

      <ImportListModal
        isVisible={isModalVisibleImportList}
        onClose={handleCancelImportList}
        onSuccess={handleOnSuccess}
      />

      <Drawer
        title=" Inquiry Details"
        width={1000}
        onClose={AddUserDetailsClose}
        open={AddUserDetailsFormModel}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        maskClosable={false}
      >
        <AddUserDetailsModel userId={userId} />
      </Drawer>
    </div>
  );
}


export default Inquiry;
