 
 


  //const serverApi = `http://localhost:3000`;  
  //const serverApi = `http://3.111.60.21:3000`; 
    const serverApi = `https://api.abrsvs.com`;   
  
 
   
export const baseUrl = serverApi;  
export const secretKey = "veryhard";
export const rozerpay_key_id = "rzp_test_7pTma2UCL0KK3r";
export const rozerpay_key_secret = "5nn9zxrobxNTJEiWtNdz4Adh";