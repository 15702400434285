// import { useState } from "react";
import { Menu, Button } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import { useState } from "react";
import { FileOutlined } from '@ant-design/icons'; // Example icon, change as needed
import logo from "../../assets/images/logo.png";
import logo1 from "../../assets/images/logo1.png";
import { getPermissions, hasPermission } from '../permissions';
import '../../assets/styles/custom_style.css';

const { SubMenu } = Menu;

function Sidenav({ color }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");

  const reportIcon = <FileOutlined />;
  //const [color, setColor] = useState("blue"); // Set your desired color

  const dashboard = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
        fill={color}
      ></path>
      <path
        d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
        fill={color}
      ></path>
      <path
        d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const tables = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path 
        d="M9 2C8.44772 2 8 2.44772 8 3C8 3.55228 8.44772 4 9 4H11C11.5523 4 12 3.55228 12 3C12 2.44772 11.5523 2 11 2H9Z"
        fill={color}
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 5C4 3.89543 4.89543 3 6 3C6 4.65685 7.34315 6 9 6H11C12.6569 6 14 4.65685 14 3C15.1046 3 16 3.89543 16 5V16C16 17.1046 15.1046 18 14 18H6C4.89543 18 4 17.1046 4 16V5ZM7 9C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11H7.01C7.56228 11 8.01 10.5523 8.01 10C8.01 9.44772 7.56228 9 7.01 9H7ZM10 9C9.44772 9 9 9.44772 9 10C9 10.5523 9.44772 11 10 11H13C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9H10ZM7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44772 15 7 15H7.01C7.56228 15 8.01 14.5523 8.01 14C8.01 13.4477 7.56228 13 7.01 13H7ZM10 13C9.44772 13 9 13.4477 9 14C9 14.5523 9.44772 15 10 15H13C13.5523 15 14 14.5523 14 14C14 13.4477 13.5523 13 13 13H10Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const users = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M16 12C18.2091 12 20 10.2091 20 8C20 5.79086 18.2091 4 16 4C13.7909 4 12 5.79086 12 8C12 10.2091 13.7909 12 16 12Z"
        fill={color}
      ></path>
      <path
        d="M8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12Z"
        fill={color}
      ></path>
      <path
        d="M8 14C5.33 14 0 15.34 0 18V20C0 20.55 0.45 21 1 21H15C15.55 21 16 20.55 16 20V18C16 15.34 10.67 14 8 14Z"
        fill={color}
      ></path>
      <path
        d="M16 14C15.7 14 15.39 14.02 15.08 14.05C16.76 15.12 18 16.67 18 18.5V20C18 20.55 18.45 21 19 21H23C23.55 21 24 20.55 24 20V18C24 15.34 18.67 14 16 14Z"
        fill={color}
      ></path>
    </svg>
    ,
  ];

  const tracking = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M12 1C5.935 1 1 5.935 1 12H3C3 7.588 7.588 3 12 3C16.412 3 21 7.588 21 12C21 16.412 16.412 21 12 21C8.945 21 6.218 19.39 4.779 16.944L6.236 15.779C7.381 17.758 9.553 19 12 19C16.411 19 20 15.411 20 11C20 6.589 16.411 3 12 3V1Z"
        fill={color}
      />
      <path
        d="M13 8H11V13L16.292 16.292L17.707 14.878L13 11.414V8Z"
        fill={color}
      />
    </svg>,

  ]

  const rtl = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 6C3 4.34315 4.34315 3 6 3H16C16.3788 3 16.725 3.214 16.8944 3.55279C17.0638 3.89157 17.0273 4.29698 16.8 4.6L14.25 8L16.8 11.4C17.0273 11.703 17.0638 12.1084 16.8944 12.4472C16.725 12.786 16.3788 13 16 13H6C5.44772 13 5 13.4477 5 14V17C5 17.5523 4.55228 18 4 18C3.44772 18 3 17.5523 3 17V6Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const profile = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const role = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M12 1C7.58984 1 4 4.58984 4 9V11C4 16.2432 7.42478 20.5611 12 21.95C16.5752 20.5611 20 16.2432 20 11V9C20 4.58984 16.4102 1 12 1ZM12 3C15.3137 3 18 5.68629 18 9V11C18 15.3381 15.2618 18.9194 12 20.2341C8.73817 18.9194 6 15.3381 6 11V9C6 5.68629 8.68629 3 12 3Z"
        fill={color}
      ></path>
      <path
        d="M9.293 11.707L11 13.414L14.707 9.707C15.098 9.316 15.098 8.684 14.707 8.293C14.316 7.902 13.684 7.902 13.293 8.293L11 10.586L9.707 9.293C9.316 8.902 8.684 8.902 8.293 9.293C7.902 9.684 7.902 10.316 8.293 10.707L9.293 11.707Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const payment = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M4 4C2.89543 4 2 4.89543 2 6V7H18V6C18 4.89543 17.1046 4 16 4H4Z"
        fill={color}
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 9H2V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V9ZM4 13C4 12.4477 4.44772 12 5 12H6C6.55228 12 7 12.4477 7 13C7 13.5523 6.55228 14 6 14H5C4.44772 14 4 13.5523 4 13ZM9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14H10C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12H9Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const signup = [
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      key={0}
    >
      <path
        d="M0,2A2,2,0,0,1,2,0H8a2,2,0,0,1,2,2V8a2,2,0,0,1-2,2H2A2,2,0,0,1,0,8Z"
        transform="translate(4 4)"
        fill={color}
      />
      <path
        d="M2,0A2,2,0,0,0,0,2V8a2,2,0,0,0,2,2V4A2,2,0,0,1,4,2h6A2,2,0,0,0,8,0Z"
        fill={color}
      />
    </svg>,
  ];

  // function getPermissions() {
  //   const storedPermission = localStorage.getItem('permission');
  //   return storedPermission ? JSON.parse(storedPermission) : {};
  // }

  // const permissions = getPermissions();

  // const hasPermission = (permissionType, permissionName) => {
  //   if (permissions === 'all') {
  //     return true;
  //   }
  //   const perm = permissions[permissionType];
  //   return perm && perm.includes(permissionName);
  // };

  return (
    <>
      <div className="brand">
        {/* <img src={logo1} alt="" /> */}
        {/* <span>Muse Dashboard</span> */}
      </div>
      <hr />
      <Menu theme="light" mode="inline">
        <Menu.Item key="1">
          <NavLink to="/dashboard">
            <span
              className="icon"
              style={{
                background: page === "dashboard" ? color : "",
              }}
            >
              {dashboard}
            </span>
            <span className="menu-item-label">Dashboard</span>
          </NavLink>
        </Menu.Item>

        {hasPermission('readPermission', 'Read User') && (
          <Menu.Item key="2">
            <NavLink to="/payments/add_user_payment">
              <span
                className="icon"
                style={{
                  background: page === "payments/add_user_payment" ? color : "",
                }}
              >
                {payment}
              </span>
              <span className="menu-item-label">Add Payment</span>
            </NavLink>
          </Menu.Item>
        )}

        {hasPermission('readPermission', 'Read User') && (
          <Menu.Item key="2">
            <NavLink to="/users">
              <span
                className="icon"
                style={{
                  background: page === "users" ? color : "",
                }}
              >
                {users}
              </span>
              <span className="menu-item-label">Member List</span>
            </NavLink>
          </Menu.Item>
        )}

        {hasPermission('readPermission', 'Read Payment') && (
          <Menu.Item key="2">
            <NavLink to="/payments-history">
              <span
                className="icon"
                style={{
                  background: page === "payments-history" ? color : "",
                }}
              >
                {payment}
              </span>
              <span className="menu-item-label">Payment List </span>
            </NavLink>
          </Menu.Item>
        )}

        <Menu.Item key="8">
          <NavLink to="/inquiry">
            <span
              className="icon"
              style={{
                background: page === "inquiry" ? color : "",
              }}
            >
              {payment}
            </span>
            <span className="menu-item-label">C/M I</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item key="8">
          <NavLink to="/visitor">
            <span
              className="icon"
              style={{
                background: page === "visitor" ? color : "",
              }}
            >
              {payment}
            </span>
            <span className="menu-item-label">Visitor</span>
          </NavLink>
        </Menu.Item>
        

        <SubMenu
          key="registration"
          title={
            <span>
              <span
                className="icon"
                style={{
                  background: page === "registration" ? color : "",
                }}
              >
                {dashboard}
              </span>
              <span className="menu-item-label">Forms</span>
            </span>
          }
        >
          <Menu.Item key="registration">
            <NavLink to="/registration">Registration Form</NavLink>
          </Menu.Item>
          <Menu.Item key="suspense-entries">
            <NavLink to="/suspense-entries">Suspense Entry</NavLink>
          </Menu.Item>
          <Menu.Item key="purnima-form">
            <NavLink to="/purnima-form">Purnima Form</NavLink>
          </Menu.Item>
          <Menu.Item key="announced">
            <NavLink to="/announced">Announced Form</NavLink>
          </Menu.Item>
          <Menu.Item key="vishesh-sahyogi">
            <NavLink to="/vishesh-sahyogi">Vishesh Sahyogi</NavLink>
          </Menu.Item>

          <Menu.Item key="village">
            <NavLink to="/village">Village Form</NavLink>
          </Menu.Item>

          <Menu.Item key="work_managment">
            <NavLink to="/work_managment">Work Managment</NavLink>
          </Menu.Item>

        </SubMenu>



        <SubMenu 
          key="reports"
          title={
            <span>
              <span
                className="icon"
                style={{
                  background: page === "reports" ? color : "",
                }}
              >
                {reportIcon}
              </span>
              <span className="menu-item-label">Reports</span>
            </span>
          }
        >
          <Menu.Item key="manual-report">
            <NavLink to="/reports/manual-report">Manually Report</NavLink>
          </Menu.Item>
          <Menu.Item key="birthday-list">
            <NavLink to="/reports/birthday-list">Birthday List</NavLink>
          </Menu.Item>
          <Menu.Item key="calling-report">
            <NavLink to="/reports/calling-report">Calling Report</NavLink>
          </Menu.Item>

          <Menu.Item key="yearly-report">
            <NavLink to="/reports/yearly-report">Yearly Report</NavLink>
          </Menu.Item>
        </SubMenu>


        {hasPermission('readPermission', 'Read Role') && (
          <Menu.Item key="2">
            <NavLink to="/role">
              <span
                className="icon"
                style={{
                  background: page === "role" ? color : "",
                }}
              >
                {role}
              </span>
              <span className="menu-item-label">Role</span>
            </NavLink>
          </Menu.Item>
        )}

        {hasPermission('readPermission', 'Read Staff') && (
          <Menu.Item key="2">
            <NavLink to="/staff">
              <span
                className="icon"
                style={{
                  background: page === "staff" ? color : "",
                }}
              >
                {users}
              </span>
              <span className="menu-item-label">Staff</span>
            </NavLink>
          </Menu.Item>
        )}

        {hasPermission('readPermission', 'Read Tracking') && (
          <Menu.Item key="2">
            <NavLink to="/tracking">
              <span
                className="icon"
                style={{
                  background: page === "tracking" ? color : "",
                }}
              >
                {tracking}
              </span>
              <span className="menu-item-label">Tracking</span>
            </NavLink>
          </Menu.Item>
        )}
        {hasPermission('readPermission', 'Read Tracking') && (
          <Menu.Item key="2">
            <NavLink to="/message-log">
              <span
                className="icon"
                style={{
                  background: page === "message-log" ? color : "",
                }}
              >
                {tracking}
              </span>
              <span className="menu-item-label">Message-Logs</span>
            </NavLink>
          </Menu.Item>
        )}

        {/* <Menu.Item key="2">
          <NavLink to="/tables">
            <span
              className="icon"
              style={{
                background: page === "tables" ? color : "",
              }}
            >
              {tables}
            </span>
            <span className="menu-item-label">Tables</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="3">
          <NavLink to="/billing">
            <span
              className="icon"
              style={{
                background: page === "billing" ? color : "",
              }}
            >
              {billing}
            </span>
            <span className="menu-item-label">Billing</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="4">
          <NavLink to="/rtl">
            <span
              className="icon"
              style={{
                background: page === "rtl" ? color : "",
              }}
            >
              {rtl}
            </span>
            <span className="menu-item-label">RTL</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item className="menu-item-header" key="5">
          Account Pages
        </Menu.Item>
        <Menu.Item key="6">
          <NavLink to="/profile">
            <span
              className="icon"
              style={{
                background: page === "profile" ? color : "",
              }}
            >
              {profile}
            </span>
            <span className="menu-item-label">Profile</span>
          </NavLink>
        </Menu.Item> */}
      </Menu>
    </>
  );
}

export default Sidenav;
