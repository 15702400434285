import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import * as XLSX from 'xlsx';
import {
  Row, Col, Card, Table, Button, Input, AutoComplete,
  notification, Modal, Select, Avatar, Typography, TableColumnsType,
  Checkbox, DatePicker, Drawer, Form, InputNumber, Tooltip, TimePicker, TimePickerProps
} from "antd";
import axios from "axios";
import { baseUrl } from "../../config";
import {
  DeleteOutlined, FileExcelOutlined, CalendarOutlined, SendOutlined,
  FilterOutlined, EditOutlined, ExclamationCircleOutlined, SearchOutlined,
  EyeOutlined, UserOutlined
} from "@ant-design/icons";
import userDummy from "../../assets/images/dummy-user.png";
import { getPermissions, hasPermission } from '../../components/permissions';
import '../../assets/styles/custom_style.css';
import moment from 'moment';
import ImportListModal from './components/importListModal';
import AddUserDetailsModel from './Form';

const { confirm } = Modal;
const { Search } = Input;
const { Option } = Select;
const { Title } = Typography;
const { Item } = Form;
const { TextArea } = Input;


// Table columns definition
const columns = [

  {
    title: "#",
    dataIndex: "key",
    key: "key",
    fixed: 'left',
    width: 50,
  },
  {
    title: "Phone no",
    dataIndex: "phone_no",
    key: "phone_no",
    fixed: 'left',
    width: 150,
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: 'Visit Branch',
    dataIndex: 'visit_branch',
    key: 'visit_branch',
  },
  {
    title: "Purpose Of Visit",
    dataIndex: "purpose_visit",
    key: "purpose_visit",
  },

  {
    title: "Visit Date",
    dataIndex: "visit_date",
    key: "visit_date",
  },
  {
    title: "Time",
    dataIndex: "visit_time_in",
    key: "visit_time_in"
  },
  {
    title: "Time Out",
    dataIndex: "visit_time_out",
    key: "visit_time_out",
  },
  {
    title: "Remark",
    dataIndex: "remark",
    key: "remark",
  },

  {
    title: "Last Sent Date",
    key: "last_date",
    dataIndex: "last_date",
    fixed: 'right',
  },
  {
    title: "Action",
    key: "action",
    dataIndex: "action",
    fixed: 'right',
  }
];

function SuspenseEntryList() {

  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const currentPageQuery = parseInt(queryParams.get('page')) || 1;
  const limitQuery = parseInt(queryParams.get('limit')) || 20;

  const [userList, setUserList] = useState([]);
  const [pagination, setPagination] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(limitQuery);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [search, setSearch] = useState('');
  const [staffList, setStaffList] = useState([]);
  const [filters, setFilters] = useState({
    name: queryParams.get('name') || '',
    email: queryParams.get('email') || '',
    phone: queryParams.get('phone') || '',
    visit_branch: queryParams.get('visit_branch') || '',
    purpose_visit: queryParams.get('purpose_visit') || '',
    visit_date: queryParams.get('visit_date') || '',
    createdAt: queryParams.get('createdAt') || '',
  });

  const [showUserFilter, setShowUserFilter] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const { id } = useParams(); // Extract id from URL 
  const [form] = Form.useForm();
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [txnDate, setTxnDate] = useState();
  const [addForm, setAddForm] = useState(false);
  const [sendMessage, setSendMessage] = useState(false);
  const [AddUserDetailsFormModel, setAddUserDetailsModel] = useState(false);
  const [userId, setuserId] = useState();
  const [isModalVisibleImportList, setIsModalVisibleImportList] = useState(false);
  const [timeInDate, setTimeInDate] = useState(null);
  const [timeOutDate, setTimeOutDate] = useState(null);


  useEffect(() => {
    const { state } = history.location; // Get the state from history
    const restoredPage = state?.page || currentPage; // Restore page from state or use current page
    const restoredLimit = state?.limit || limit; // Restore limit from state or use current limit

    // Call getUserList with restored page and limit
    getUserList(restoredPage, restoredLimit, sortField, sortOrder, search, filters);
  }, [history.location]);


  const AddUserDetailsClose = () => {
    setAddUserDetailsModel(false)
    const { state } = history.location; // Get the state from history
    const restoredPage = state?.page || currentPage; // Restore page from state or use current page
    const restoredLimit = state?.limit || limit; // Restore limit from state or use current limit

    // Call getUserList with restored page and limit
    getUserList(restoredPage, restoredLimit, sortField, sortOrder, search, filters);
  }



  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  async function getUserList(page = 1, limit = 20, sortField = null, sortOrder = null, search = '', filters = {}) {
    try {
      setCurrentPage(page);
      setLimit(limit);
      const sortParam = sortField && sortOrder ? `&sortField=${sortField}&sortOrder=${sortOrder}` : '';
      const searchParam = search ? `&search=${search}` : '';
      const filterParams = Object.keys(filters)
        .map(key => `${key}=${filters[key]}`)
        .join('&');
      const response = await axios.get(`${baseUrl}/visitor/list?page=${page}&limit=${limit}${sortParam}${searchParam}&${filterParams}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        }
      });
      if (response.data.status) {
        setUserList(response.data.result);
        setPagination({
          current: page,
          pageSize: limit,
          total: response.data.totalCount
        });
      } else {
        notification.info({
          message: 'Info',
          description: response.data.message,
          placement: 'topRight'
        });
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }
  };

  async function handleDelete(id) {
    try {
      const response = await axios.delete(`${baseUrl}/visitor/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      if (response.status === 200) {
        getUserList(pagination.current, pagination.pageSize, sortField, sortOrder, search, filters);
        notification.success({
          message: 'Success',
          description: 'Record deleted successfully!',
          placement: 'topRight'
        });
      } else {
        notification.info({
          message: 'Info',
          description: response.data.message,
          placement: 'topRight'
        });
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: 'Are you sure you want to delete this item?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDelete(id);
      },
    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const { field, order } = sorter;
    const formattedOrder = order === 'ascend' ? 'asc' : 'desc';
    setSortField(field);
    setSortOrder(formattedOrder);
    setFilters(filters);

    // Update both current page and limit in the history
    history.push(`?page=${current}&limit=${pageSize}`);

    // Fetch the updated payment list
    getUserList(current, pageSize, field, formattedOrder, search, filters);
  };



  const handleLimitChange = value => {
    setLimit(value);

    // Update URL with the new limit and reset page to 1
    const updatedQueryParams = new URLSearchParams(location.search);
    updatedQueryParams.set('limit', value);
    updatedQueryParams.set('page', 1); // Reset to first page whenever limit changes

    // Push new URL with updated query params
    history.push(`?${updatedQueryParams.toString()}`);

    // Call the API to fetch data with updated limit
    getUserList(1, value);  // Assuming your API call accepts page and limit
  };




  const handleFilterChange = (field, value) => {
    const newFilters = {
      ...filters,
      [field]: value
    };
    setFilters(newFilters);

    // Optionally, update the URL without triggering a search immediately
    const updatedQueryParams = new URLSearchParams(location.search);
    Object.keys(newFilters).forEach(key => {
      if (newFilters[key]) {
        updatedQueryParams.set(key, newFilters[key]);
      } else {
        updatedQueryParams.delete(key); // Remove empty filters from the URL
      }
    });
    history.push(`?${updatedQueryParams.toString()}`);
  };


  const handleUserFilterCheckboxChange = (e) => {
    setShowUserFilter(e.target.checked);

  };



  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = async (values) => {
    try {

      if (txnDate) {
        const formattedDate = moment(txnDate).format('YYYY-MM-DD');
        values.visit_date = formattedDate;
      } else {
        values.visit_date = '';
      }


      if (timeInDate) {
        const formattedDate = moment(timeInDate).format('h:mm:ss A');
        values.visit_time_in = formattedDate;
      } else {
        values.visit_time_in = '';
      }

      if (timeOutDate) {
        const formattedDate = moment(timeOutDate).format('h:mm:ss A');
        values.visit_time_out = formattedDate;
      } else {
        values.visit_time_out = '';
      }


      values.action = sendMessage ? 'save_send' : 'save';
      if (isUpdateMode) {
        const response = await axios.patch(`${baseUrl}/visitor/update/${id}`, values, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
          },
        });
        if (response.status === 200) {
          notification.success({
            message: 'Success',
            description: 'Record updated successfully!',
            placement: 'topRight'
          });
          history.push('/suspense-entries');
        } else {
          notification.info({
            message: 'Info',
            description: response.data.message,
            placement: 'topRight'
          });
        }
      } else {
        const response = await axios.post(`${baseUrl}/visitor/create`, values, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
          },
        });
        if (response.status === 200) {
          notification.success({
            message: 'Success',
            description: 'Visitor added successfully!',
            placement: 'topRight'
          });
          form.resetFields();
          setTxnDate('');

          getUserList();

          // history.push('/visitor');
        } else {
          notification.info({
            message: 'Info',
            description: response.data.message,
            placement: 'topRight'
          });
        }
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }
  };



  const handleDateChange = (date, dateString) => {
    if (date) {
      const formattedDate = moment(date).format('YYYY-MM-DD');
      setTxnDate(formattedDate)
    } else {
      setTxnDate(null);
    }
  };

  const toggleFormVisibility = () => {
    setAddForm(prevState => !prevState); // Toggle between true and false
  };


  const handleResendMessage = async (userId) => {
    Modal.confirm({
      title: 'Are you sure you want to Re-send message?',
      onOk: async () => {
        try {
          const response = await axios.patch(`${baseUrl}/visitor/messageresend/${userId}`, { send: 'yes' }, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
            },
          });

          if (response.status === 200) {

            const { state } = history.location; // Get the state from history
            const restoredPage = state?.page || currentPage; // Restore page from state or use current page
            const restoredLimit = state?.limit || limit; // Restore limit from state or use current limit

            // Call getUserList with restored page and limit
            getUserList(restoredPage, restoredLimit, sortField, sortOrder, search, filters);

            notification.success({
              message: 'Success',
              description: 'Message sent successfully',
              placement: 'topRight',

            });
          }
        } catch (error) {
          notification.info({
            message: 'Info',
            description: "Failed to verify payment",
            placement: 'topRight'
          });

        }
      },
    });
  }

  const exportToExcel = async () => {
    const sortParam = sortField && sortOrder ? `&sortField=${sortField}&sortOrder=${sortOrder}` : '';
    const searchParam = search ? `&search=${search}` : '';
    const filterParams = Object.keys(filters)
      .map(key => `${key}=${filters[key]}`)
      .join('&');
    const response = await axios.get(`${baseUrl}/visitor/list?page=${1}&limit=all${sortParam}${searchParam}&${filterParams}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      }
    });
    if (response.data.status) {
      const formattedData = response.data.result.map((user, index) => ({
        "Sr.No": index + 1,
        "Phone no": user.phone_no,
        "Name": user.name,
        "Visit Branch": user.visit_branch,
        "Purpose Visit": user.purpose_visit,
        "Visit Date": user.visit_date ? moment(user.visit_date).format('DD-MM-YYYY') : '-',
        "Visit Time": user.visit_time_in,
        "Visit Time Out": user.visit_time_out,
        "Create Date": user.createdAt ? moment(user.createdAt).format('DD-MM-YYYY') : '-'
      }));

      const worksheet = XLSX.utils.json_to_sheet(formattedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Vistor-list");
      XLSX.writeFile(workbook, "Vistor-List.xlsx");
    } else {
      notification.info({
        message: 'Info',
        description: response.data.message,
        placement: 'topRight'
      });
    }
  };


  const AddUserDetails = async (userId) => {
    console.log('uid', userId)
    setuserId(userId);
    setAddUserDetailsModel(true)

  }

  const handleCancelImportList = () => {
    setIsModalVisibleImportList(false);
  };

  const handleOnSuccess = () => {
    setIsModalVisibleImportList(false);
    getUserList();
  };





  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>



        {addForm && (
          <Col xs="24" xl={24}>
            <div className="tabled">
              <Row gutter={[24, 0]}>
                <Col xs="24" xl={24}>
                  <Card
                    bordered={false}
                    className="criclebox tablespace mb-24"
                    title={isUpdateMode ? "Update Vistor Form" : "Add Vistor Form"}
                  >
                    <Form
                      initialValues={{ visit_branch: "श्री खेतेश्वर गुरुकुल, मायलावास" }}
                      style={{ "padding": "20px" }} form={form} onFinish={onFinish} layout="vertical">

                      <Row gutter={[16, 16]}>


                        <Col xs={24} sm={4} lg={4}>
                          <Item
                            label="Phone No"
                            name="phone_no"
                            rules={[{ required: true, message: 'Please Enter  Phone no' },
                            {
                              pattern: /^\d{10,12}$/,
                              message: 'Phone no must be between 10 and 12 digits!',
                            }
                            ]}
                          >

                            <Input type="number" placeholder="Enter Phone no" />
                          </Item>
                        </Col>
                        <Col xs={24} sm={4} lg={4}>
                          <Item
                            label="Name"
                            name="name"
                            rules={[{ required: true, message: 'Please Enter Name' }]}
                          >
                            <Input placeholder="Enter Name" />
                          </Item>
                        </Col>
                        <Col xs={24} sm={6} lg={4}>


                          <Item
                            label="Visit Branch"
                            name="visit_branch"
                          >
                            <Select placeholder="Select Branch" defaultValue="श्री खेतेश्वर गुरुकुल, मायलावास">
                              <Option value="अखिल भारतीय राजपुरोहित समाज विकास संस्थान">अखिल भारतीय राजपुरोहित समाज विकास संस्थान</Option>
                              <Option value="श्री खेतेश्वर गुरुकुल, मायलावास">श्री खेतेश्वर गुरुकुल, मायलावास</Option>
                              <Option value="श्री खेतेश्वर गुरुकुल, बिजरोल खेड़ा">श्री खेतेश्वर गुरुकुल, बिजरोल खेड़ा</Option>
                              <Option value="श्री खेतेश्वर गुरुकुल, सायला">श्री खेतेश्वर गुरुकुल, सायला</Option>
                              <Option value="श्री खेतेश्वर गुरुकुल, सवाई छोटी">श्री खेतेश्वर गुरुकुल, सवाई छोटी</Option>
                              <Option value="श्री खेतेश्वर भवन, हरिद्वार">श्री खेतेश्वर भवन, हरिद्वार</Option>
                            </Select>

                          </Item>
                        </Col>



                        <Col xs={24} sm={4} lg={4}>
                          <Item label="Visit Date" >
                            <DatePicker onChange={handleDateChange} value={txnDate && moment(txnDate)} className="filter_dob" format="DD-MM-YYYY" placeholder="Select Date" prefix={<CalendarOutlined />} />

                          </Item>
                        </Col>

                        <Col xs={24} sm={12} lg={4}>
                          <Item label="Visit Time In" name="visit_time_in">
                            <TimePicker
                              use12Hours
                              format="h:mm A"
                              // Pass valid moment object or null
                              onChange={(time) => setTimeInDate(time)}
                            />
                          </Item>
                        </Col>

                        <Col xs={24} sm={12} lg={4}>
                          <Item label="Visit Time Out" name="visit_time_out">
                            <TimePicker
                              use12Hours
                              format="h:mm A"
                              // Pass valid moment object or null
                              onChange={(time) => setTimeOutDate(time)}
                            />
                          </Item>
                        </Col>

                        <Col xs={24} sm={6} lg={4}>
                          <Item
                            label="Purpose of Visit"
                            name="purpose_visit"
                          >
                            <Input placeholder="Enter Purpose Visit" />
                          </Item>
                        </Col>

                        <Col xs={24} sm={12} lg={8}>
                          <Item
                            label="Remark"
                            name="remark"
                          >
                            <Input placeholder="Enter Remark" />
                          </Item>
                        </Col>

                      </Row>
                      <Row style={{ textAlign: 'right' }}>
                        <Col xs={24} sm={24} lg={24}>
                          <Item>
                            <Button
                              type="primary"
                              htmlType="submit"
                              onClick={() => setSendMessage(false)}
                              style={{ marginRight: '10px' }} // Add space between buttons
                            >
                              {isUpdateMode ? "Update" : "Save"}
                            </Button>

                            <Button
                              type="primary"
                              htmlType="submit"
                              onClick={() => setSendMessage(true)}
                            >
                              {isUpdateMode ? "Update" : "Save/Send"}
                            </Button>
                          </Item>
                        </Col>
                      </Row>

                    </Form>
                  </Card>
                </Col>
              </Row>
            </div>
          </Col>

        )}





        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="New Vistor Form List"
            extra={
              <div style={{ display: 'flex', gap: '10px' }}>

                <Button
                  type="default"
                  icon={<FileExcelOutlined />}
                  onClick={() => setIsModalVisibleImportList(true)}
                  style={{
                    padding: '4px 15px',
                    fontSize: '14px',
                    lineHeight: '22px',
                    borderRadius: '5px',
                  }}
                >
                  Import List
                </Button>

                {hasPermission('createPermission', 'Create User') && (

                  <button onClick={toggleFormVisibility} className="custom-btn">Add Vistor Form</button>


                )}
                <Button
                  type="default"
                  icon={<FileExcelOutlined />}
                  onClick={exportToExcel}
                  style={{
                    padding: '4px 15px',
                    fontSize: '14px',
                    lineHeight: '22px',
                    borderRadius: '5px',
                  }}
                >
                  Excel Download
                </Button>
              </div>
            }

          >
            <div className="table-responsive">
              <Table
                columns={columns}
                pagination={false}
                scroll={{ x: 'max-content' }}
                bordered
                title={() => (
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Row gutter={[16, 16]}>
                      <Col xs={24}>
                        <FilterOutlined /> <Checkbox onChange={handleUserFilterCheckboxChange}><Title style={{ fontSize: 13 }} level={5}>Form Filter</Title></Checkbox>
                      </Col>
                      {showUserFilter && (
                        <>
                          <Col span={4}> <Input placeholder="Phone no" value={filters.phone_no} onChange={e => handleFilterChange('phone_no', e.target.value)} /></Col>

                          <Col span={4}><Input placeholder="Name" value={filters.name} onChange={e => handleFilterChange('name', e.target.value)} /></Col>


                          <Col span={4}>
                            <Select style={{ width: '100%' }} placeholder="Select Branch" value={filters.visit_branch} onChange={value => handleFilterChange('visit_branch', value)}>


                              <Option value="">Select Branch</Option>
                              <Option value="अखिल भारतीय राजपुरोहित समाज विकास संस्थान">अखिल भारतीय राजपुरोहित समाज विकास संस्थान</Option>
                              <Option value="श्री खेतेश्वर गुरुकुल, मायलावास">श्री खेतेश्वर गुरुकुल, मायलावास</Option>
                              <Option value="श्री खेतेश्वर गुरुकुल, बिजरोल खेड़ा">श्री खेतेश्वर गुरुकुल, बिजरोल खेड़ा</Option>
                              <Option value="श्री खेतेश्वर गुरुकुल, सायला">श्री खेतेश्वर गुरुकुल, सायला</Option>
                              <Option value="श्री खेतेश्वर गुरुकुल, सवाई छोटी">श्री खेतेश्वर गुरुकुल, सवाई छोटी</Option>
                              <Option value="श्री खेतेश्वर भवन, हरिद्वार">श्री खेतेश्वर भवन, हरिद्वार</Option>
                            </Select>
                          </Col>

                          <Col span={4}><Input placeholder="Purpose Visit" value={filters.purpose_visit} onChange={e => handleFilterChange('purpose_visit', e.target.value)} /></Col>


                          <Col span={4}>
                            <DatePicker className="filter_dob CustomInputSet"
                              format="DD-MM-YYYY" value={filters.visit_date ? moment(filters.visit_date, 'DD-MM-YYYY') : null}
                              onChange={(date, dateString) => handleFilterChange('visit_date', dateString)} placeholder="Visit Date"
                            />
                          </Col>



                        </>
                      )}

                      <Col xs={24}>
                        <span style={{ marginRight: 10 }}>Records per page:</span>
                        <Select defaultValue={20} onChange={handleLimitChange}>
                          <Option value={20}>20</Option>
                          <Option value={40}>40</Option>
                          <Option value={60}>60</Option>
                          <Option value={80}>80</Option>
                          <Option value={100}>100</Option>
                        </Select>
                      </Col>
                    </Row>
                  </div>
                )}


                dataSource={userList.map((user, index) => {

                  const isMatch = user.phone_no === user.user_phone_no;

                  return {
                    key: (limit * (currentPage - 1)) + index + 1,

                    phone_no: (
                      <div className="author-info">
                        <Tooltip title="Number match with origin member!">
                          <Title
                            level={5}
                            style={{
                              textAlign: 'center',
                              color: isMatch ? 'white' : 'inherit',
                              backgroundColor: isMatch ? 'green' : 'inherit',
                              padding: isMatch ? '5px' : 'inherit',
                              borderRadius: isMatch ? '5px' : 'inherit'
                            }}
                          >
                            {user.phone_no}
                          </Title>
                        </Tooltip>
                      </div>
                    ),




                    name: (
                      <>
                        <div className="author-info">
                          <Title level={5}> {user.name}</Title>
                        </div>
                      </>
                    ),
                    visit_branch: (
                      <>
                        <div className="author-info">
                          <Title level={5}>{user.visit_branch}  </Title>
                        </div>
                      </>
                    ),
                    purpose_visit: (
                      <>
                        <div className="author-info">
                          <Title level={5}>{user.purpose_visit}  </Title>
                        </div>
                      </>
                    ),

                    visit_date: (
                      <>
                        <div className="author-info">
                          <Title level={5}>{user.visit_date && user.visit_date != "0000-00-00" ? moment(user.visit_date).format('DD MMMM YYYY') : '-'}</Title>
                        </div>
                      </>
                    ),
                    visit_time_in: (
                      <>
                        <div className="author-info">
                          <Title level={5}>
                            {user.visit_time_in && user.visit_time_in !== "00:00:00"
                              ? moment(user.visit_time_in, "HH:mm:ss").format("h:mm A") // Format as 12-hour time with AM/PM
                              : "-"}
                          </Title>
                        </div>
                      </>
                    ),

                    visit_time_out: (
                      <>
                        <div className="author-info">
                          <Title level={5}>
                            {user.visit_time_out && user.visit_time_out !== "00:00:00"
                              ? moment(user.visit_time_out, "HH:mm:ss").format("h:mm A") // Format as 12-hour time with AM/PM
                              : "-"}
                          </Title>
                        </div>
                      </>
                    ),

                   

                    remark: (
                      <>
                        <div className="author-info">
                          <Title level={5}>{user.remark}  </Title>
                        </div>
                      </>
                    ),



                    createdAt: (
                      <>
                        <div className="author-info">
                          <Title level={5}>{user.createdAt && user.createdAt != "0000-00-00" ? moment(user.createdAt).format('DD MMMM YYYY') : '-'}</Title>

                        </div>
                      </>
                    ),

                    last_date: (
                      <>
                        <div className="author-info">
                          <Title level={5}>{user.last_date && user.last_date != '0000-00-00' ? moment(user.last_date).format('DD MMMM YYYY') : '-'}</Title>

                        </div>
                      </>
                    ),


                    action: (
                      <div className="button-container" style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>

                        <Button onClick={() => AddUserDetails(user.id)} className="update-btn">
                          <EditOutlined />
                        </Button>

                        <Button onClick={() => showDeleteConfirm(user.id)} className="delete-btn">
                          <DeleteOutlined />
                        </Button>

                        <Button icon={<SendOutlined />} onClick={() => handleResendMessage(user.id)}>
                          {user.is_send === 1 ? 'Re-send' : 'Send'}

                        </Button>

                      </div>
                    )
                  };
                })}
                pagination={{
                  ...pagination,
                  showTotal: total => `Total ${total} records`,
                  style: { marginRight: 20 }
                }}
                onChange={handleTableChange}
                className="ant-border-space"
              />
            </div>
          </Card>
        </Col>
      </Row>

      <Modal
        open={isModalVisible}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          src={currentImage}
          alt="Profile"
          style={{ width: '100%' }}
        />
      </Modal>




      <Drawer
        title="Vistor Details"
        width={1000}
        onClose={AddUserDetailsClose}
        open={AddUserDetailsFormModel}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        maskClosable={false}
      >


        <AddUserDetailsModel userId={userId} />

      </Drawer>

      <ImportListModal
        isVisible={isModalVisibleImportList}
        onClose={handleCancelImportList}
        onSuccess={handleOnSuccess}
      />



    </div>
  );
}


export default SuspenseEntryList;
